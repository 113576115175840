* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  background: #fff;
  height: 100vh;
}

.main-container {
  width: 100%;
  height: 100%;
}

/* Nav */
.nav-container {
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  background: #fff;
  flex-direction: row;
  padding: 1.2rem;
  align-items: center;
  box-shadow: 2px 2px 4px gray;
  justify-content: space-between;
  width: 100%;
  height: 9vh;
}

.nav-container.active {
  background: fff;
  height: 9vh;
  border-radius: 2px;
}

.logo {
  font-family: "Acme", sans-serif;
  font-size: 1.8rem;
  color: #55198b;
  cursor: pointer;
}

.link {
  list-style-type: none;
  float: left;
}

.nav-links .anchor {
  display: inline-block;
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-family: "Varela Round", sans-serif;

  font-size: 1rem;
  font-weight: 400;
  line-height: 9.1vh;
  margin: 0.8rem;
}

.nav-links .anchor:hover {
  color: #55198b;
}

.anchor.active {
  color: #55198b;
  font-weight: 800;
  border-bottom: 2px solid #55198b;
}

.menu-icon {
  display: none;
}

/* Home */
.home-container {
  font-family: "Varela Round", sans-serif;
  display: flex;
  color: white;
  flex-direction: row;
  background-color: rgb(29, 27, 27);
  height: 100%;
  padding: 1rem;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.content-container {
  width: 50vw;
  height: 100%;
  flex-direction: column;
  display: flex;
  padding: 1.8rem;
  margin-top: 3rem;
  justify-content: center;
}

.main-content {
  font-size: 3rem;
}

.skill-content {
  margin: 0.6rem 0;
  color: #9c72be;
  font-family: "Varela Round", sans-serif;

  font-weight: 400;
  font-size: 1.6rem;
}

.skill-animation {
  height: 60px;
  width: 100%;
  margin: 1rem 0;
}

.hand {
  display: inline-block;
  margin: 0.9rem;
  cursor: pointer;
}

.welcome-content {
  color: gray;
}

.svg-container {
  margin-top: 1.5rem;
  width: 50vw;
  height: 36rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  height: 75%;
}

/* Services */

.services-container {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 170vh;
  flex-direction: column;
  font-family: "Exo 2", sans-serif;
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  padding: 2rem;
}
.services-container {
  margin-top: 18rem;
}

.services-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 4rem;
  height: 30vh;
  flex-wrap: wrap;
  flex-direction: row;
}
.services-wrapper-two {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 0.2rem;
  height: 30vh;
  flex-wrap: wrap;
  min-height: 60vh;
  width: 100%;
  flex-direction: row;
}
.projects-services {
  height: 9rem;
  width: 300px;
  margin: .5rem;
  /* background: #e7dddd5c; */
  box-shadow: 3px 2px 10px gray;
  cursor: pointer;
  border-radius: 20px;
}

.services {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 400px;
  text-align: center;
  /* background: #e7dddd5c; */
  margin: 1rem;
  /* box-shadow: 0 10px 10px -14px rgba(0,0,0,.2); */
  box-shadow: 3px 2px 10px gray;
  height: 9rem;
  border-radius: 20px;
  padding: 3rem;
  text-align: center;
}

/* .projects-services :hover {
  border: 1px solid gray;
} */

.projects-services img {
  height: 5.7rem;
  margin-top: 0.5rem;
  border: 1px solid rgb(153, 151, 151);

}

.projects-services li {
  list-style-type: none;
  font-family: "Varela Round", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

/* Skills */

.skills-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
}


.aboutme-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
}


.skills-container h1 {
  margin: 2rem 0;
}

.techs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 95%;
  /* background: #e7dddd5c; */

  box-shadow: 3px 2px 10px gray;
  min-height: 12rem;
  border-radius: 20px;
  padding: 3rem;
  flex-wrap: wrap;
  margin: 2rem;
  text-align: center;
}

.techs:hover {
  border: 1px solid gray;
}

.techs i {
  margin: 1.1rem;
  font-size: 4.8rem;
}

.techs li {
  list-style-type: none;
  font-family: "Varela Round", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.techs .html {
  color: rgb(204 78 29);
}

.techs .css {
  color: #5898dc;
}

.techs .javascript {
  color: #ecec1c;
}

.techs .bootstrap {
  color: #9300ff;
}

.techs .sass {
  color: #c300ff;
}

.techs .react {
  color: #29c2ff;
}

.techs .typescript {
  color: #042f67;
}

.techs .python {
  color: #82e1ff;
}

/* Portfolio */
.portfolio-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
}

.portfolio-container h1 {
  margin: 3rem 0;
}

.projects-container {
  min-height: 60vh;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.projects-container-services {
  min-height: 25vh;
  max-height: 50vh;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.projects {
  height: 23rem;
  width: 21rem;
  margin: 1rem;
  /* background: #e7dddd5c; */
  box-shadow: 3px 2px 10px gray;
  cursor: pointer;
  border-radius: 10px;
}


.project-images {
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #55198b;
  height: 70%;
}

/* Images Proportion (877 * 679)*/

.project-links {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  justify-content: center;
  align-items: center;
}

.project-links .text {
  width: 100%;
  height: 50%;
  color: black;
  font-family: "Varela Round", sans-serif;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-links .icons {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons #github {
  background-color: rgb(51, 51, 51);
}

.icons #github:hover {
  background-color: rgb(15, 15, 15);
}

.icons #live {
  background-color: rgb(16, 88, 16);
}

.icons #live:hover {
  background-color: rgb(16, 65, 88);
}

.icons i {
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  position: relative;
  text-align: center;
  user-select: none;
  width: 2.6rem;
  border-radius: 2.6rem;
  margin: 0px 5px 10px;
  transition: all 0.2s ease-in 0s;
}

/* Achievements */
.achievements-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;

}

/* Images Proportion (877 * 679) */
#fundamentals_ML_finance {
  background-image: url("./images/certificates/fundamentals_ML_finance.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#complete_reinforcement_LS {
  background-image: url("./images/certificates/complete_reinforcement_LS.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#sequence_models {
  background-image: url("./images/certificates/sequence_models.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#neural_network {
  background-image: url("./images/certificates/neural_network.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#prediction_control_func {
  background-image: url("./images/certificates/prediction_control_func.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#intro_trading_ML_gcp {
  background-image: url("./images/certificates/intro_trading_ML_gcp.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#trading_strategies {
  background-image: url("./images/certificates/trading_strategies.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#trading_strategies {
  background-image: url("./images/certificates/trading_strategies.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#structure_ML_projects {
  background-image: url("./images/certificates/structure_ML_projects.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#badge_img {
  background-image: url("https://images.credential.net/badge/tiny/hnutwwqh_badge.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#tensorflow_services {
  background-image: url("./images/certificates/tensorflow_services.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#specialization_deeplearning {
  background-image: url("./images/certificates/specialization_deeplearning.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#specialization_reinforcemnetlearning {
  background-image: url("./images/certificates/specialization_reinforcemnetlearning.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#tour_ML_finance {
  background-image: url("./images/certificates/tour_ML_finance.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#ML_intradingfinance {
  background-image: url("./images/certificates/ML_intradingfinance.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#seq_time_prediction {
  background-image: url("./images/certificates/seq_time_prediction.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#improving_deaplearning {
  background-image: url("./images/certificates/improving_deaplearning.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#sample_based_learningmethods {
  background-image: url("./images/certificates/sample_based_learningmethods.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#neurallanguage_tensorflow {
  background-image: url("./images/certificates/neurallanguage_tensorflow.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#neural_network_deeplearning {
  background-image: url("./images/certificates/neural_network_deeplearning.png");
  background-size: cover;
  background-repeat: no-repeat;
}
/* Contact */
.contact-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
}

.profile-image {
  margin: 1rem;
  box-shadow: 6px 6px 10px gray;
  width: 170px;
  border: 1px solid black;
  border-radius: 50%;
  background-image: url("./images/pic.jpg");
  background-size: cover;
  height: 170px;
}

.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
}

.cards {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  box-shadow: 3px 2px 10px gray;
  border-radius: 20px;
  flex-direction: column;
  width: 16rem;
  /* background: #e7dddd5c; */
  height: 9rem;
  margin: 1rem;
  padding: 1.3rem 0;
}

.cards:hover {
  border: 1px solid gray;
}

.contact-icons {
  display: flex;
  align-items: center;
  width: 100%;
  height: 33.33%;
  text-align: center;
  justify-content: center;
}

.contact-text {
  display: flex;
  margin-top: 0.7rem;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  height: 33.33%;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
}

.contact-links {
  margin-top: 0.7rem;
  display: flex;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  width: 90%;
  height: 33.33%;
  align-items: center;
  justify-content: center;
}

.contact-links > span {
  display: block;
}

.contact-links i {
  cursor: pointer;
  margin: 0.3rem;
  display: block;
  font-size: 1.3rem;
  position: relative;
  text-align: center;

  padding: 0.1rem;

  user-select: none;
}

.contact-links a {
  text-decoration: none;
}

.contact-links i:hover {
  box-shadow: 2px 2px 4px gray;

  border-radius: 20%;
}

.contact-links #github {
  color: rgba(0, 0, 0, 0.884);
}

.contact-links:hover #github {
  color: rgb(0, 0, 0);
}

.contact-links #facebook {
  color: rgba(28, 28, 231, 0.938);
}

.contact-links:hover #facebook {
  color: rgb(28, 28, 231);
}

.contact-links #instagram {
  color: rgba(214, 84, 45, 0.89);
}

.contact-links:hover #instagram {
  color: rgb(214, 84, 45);
}

.contact-links #linkedin {
  color: #3d8eb7ea;
}

.contact-links:hover #linkedin {
  color: #3d8eb7;
}

.contact-links #twitter {
  color: #00b7fff1;
}

.contact-links:hover #twitter {
  color: #00b7ff;
}

.contact-icons i {
  color: #f35166;
  cursor: pointer;
  font-size: 1.7rem;
  position: relative;
  user-select: none;
  transition: all 0.2s ease-in 0s;
}

/* Footer */
.footer-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  color: black;
  font-size: 0.7rem;
  /* background: rgb(58, 55, 55); */
  padding: 2rem;
  border-top: 2px solid black;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 8vh;
}

.footer-container a {
  text-decoration: none;
  font-family: "Varela Round", sans-serif;

  color: #9300ff;
  text-shadow: 1px 1px 1px black;
}

.footer-container a:hover {
  color: #5bbd22c5;
}

/* General */

.button-wrapper {
  width: 100%;

}

.button:hover {
  background-color: #fff;
  color: #55198b;
  transition: all 0.3s ease 0s;
  transform: translateY(-3px);
}

.button-link {
  text-decoration: none;
}

.button {
  background-color: #55198b;
  border: 1px solid #55198b;
  color: #fff;
  font-weight: 700;
  min-width: 200px;
  padding: 13px 22px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  outline: none;
  margin-top: 3.6rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}

.contact-btn {
  margin-right: 1rem;
}

.heading {
  margin: 2rem 0;
  font-family: "Varela Round", sans-serif;
  color: black;
}

/* Media Queries */
/* 1150 Breakpoint */
@media screen and (max-width: 1150px) {
  .home-container {
    flex-direction: column;
  }
  .content-container {
    padding-top: 5rem;
    width: 100vw;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .svg-container {
    width: 100vw;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 10rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }
}

/*900 Mobile Nav Version */

@media screen and (max-width: 900px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px;
    position: absolute;
    top: 9vh;
    text-align: center;
    align-items: center;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-links.active {
    background: rgba(255, 255, 255, 0.911);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .menu-icon {
    display: block;
    position: relative;
    margin: 0 1rem;
    font-size: 1.8rem;
    cursor: pointer;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 0.2rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }

  .nav-links .anchor {
    display: block;
    line-height: 9.1vh;
    width: 100vw;
    margin: 0.4rem;
  }

  .anchor.active {
    color: white;
    font-weight: 800;
    border: none;
    background: rgba(0, 0, 0, 0.534);
  }

  .nav-links.active .anchor:hover {
    color: black;
  }
}

/* 700 */

@media screen and (max-width: 700px) {
  .svg-container {
    margin-top: 1.5rem;
    width: 50vw;
    height: 36rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .svg {
    height: 60%;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 22rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }
}

/* 500 */
@media screen and (max-width: 500px) {
  button {
    width: 90%;
  }

  .contact-btn {
    margin-right: 0;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 20rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }
}

/* 550 */
@media screen and (max-width: 550px) {
  .content-container {
    font-size: 0.7rem;
  }

  .main-content {
    font-size: 2rem;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 20rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }
  .skill-content {
    margin: 0.6rem 0;
    font-size: 1.2rem;
  }

  .hand {
    font-size: 1.8rem;
  }

  .welcome-content {
    font-size: 0.7rem;
  }
}

/* 500 */
@media screen and (max-width: 500px) {
  .svg {
    height: 40%;
  }
  .about-me-container {
    /* margin-top: -100px; */
    font-family: "Varela Round", sans-serif;
    margin-bottom: 2px;
    color: black;
    text-align: center;
    font-size: 1rem;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 20rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }
  
}

/* 415 */

@media screen and (max-width: 415px) {
  .skill-content {
    font-size: 1rem;
  }
  .about-me-container {
    /* margin-top: -100px; */
    font-family: "Varela Round", sans-serif;
    color: black;
    text-align: center;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 20rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }
  
}

/* 395*/

@media screen and (max-width: 395px) {
  .heading {
    font-size: 1.2rem;
  }
  .button {
    font-size: 0.8rem;
  }
  .aboutme-container h1 {
    margin-top: -60px;
    font-family: "Varela Round", sans-serif;
    color: black;
    text-align: center;
  }

  .aboutme-container {
    font-family: "Exo 2", sans-serif;
    display: flex;
    color: rgb(0, 0, 0);
    font-size: 1.3rem;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    min-height: 100vh;
  }

  .services-container {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 170vh;
    flex-direction: column;
    font-family: "Exo 2", sans-serif;
    color: rgb(0, 0, 0);
    font-size: 1.3rem;
    padding: 2rem;
  }
  .services-container {
    margin-top: 8rem;
  }
  
  .services-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 4rem;
    height: 30vh;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .services-wrapper-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 20rem;
    height: 30vh;
    flex-wrap: wrap;
    min-height: 60vh;
    width: 100%;
    flex-direction: row;
  }
  
  
}
.about-me-container {
  margin-top: -100px;
  font-family: "Varela Round", sans-serif;
  color: black;
  text-align: center;
}
